<template>
  <div id="query">
    <div class="company_name">
      <img src="../../assets/yige_logo.png" alt="" />
    </div>
    <div class="anti_fake">
      <img src="../../assets/fangwei.png" alt="" />
    </div>
    <h3>智能设备真伪鉴别</h3>
    <div class="explain">
      <h4>1.获取防伪码</h4>
      <p>
        您可以在产品的包装盒上找到此防伪标签，然后轻轻刮开涂成后即可获取防伪码
      </p>
    </div>
    <div class="explains">
      <h4>2.真伪鉴别查询</h4>
      <div class="security_code">
        <input
          type="text"
          v-model="data.securityCode"
          @input="changeinput"
          placeholder="请输入防伪码"
        />
        <div class="verify_code">
          <input
            type="text"
            @input="changeinputs"
            v-model="data.validateCode"
            placeholder="请输入验证码"
          /><img @click="getcode()" :src="imgurl" alt="" />
        </div>
        <button class="btn" v-if="!show" disabled>查询</button>
        <button class="btns" @click="search" v-else>查询</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgurl: "",

      show: false,
      data: {
        uuid: "",
        securityCode: "",
        validateCode: "",
      },
    };
  },
  created() {
    this.getcode();
    console.log(this.$CheckIsAndroid());
  },
  mounted() {},
  methods: {
    changeinput() {
      if (this.data.securityCode != "" && this.data.validateCode != "") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    changeinputs() {
      if (this.data.securityCode != "" && this.data.validateCode != "") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    async getcode() {
      const { data: res } = await this.$api.query.getValidateCode();
      console.log(res);
      this.imgurl = "data:image/jpeg;base64," + res.img;
      this.data.uuid = res.uuid;
    },
    async search() {
      const res = await this.$api.query.SearchResult(this.data);
      console.log(res);
      if (res.code == "00000" && !res.data) {
        this.$router.push({
          name: "SearchResult",
          query: {
            show: false,
          },
        });
      } else if (res.code == "00000" && res.data) {
        this.$router.push({
          name: "SearchResult",
          query: {
            show: true,
            checkCode: res.data.checkCode,
            num: res.data.num,
          },
        });
      }
      console.log(res);
    },
  },
};
</script>
<style scope lang="scss">
#query {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  .company_name {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 116px;
    border-bottom: 2px solid #ededed;
    margin-bottom: 112px;
    img {
      width: 200px;
      height: 54px;
    }
  }
  .anti_fake {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 136px;
    img {
      width: 120px;
      height: 100%;
    }
  }
  h3 {
    width: 100%;
    // height: 48px;
    text-align: center;
    font-size: 48px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-top: 48px;
    margin-bottom: 62px;
  }
  .explain {
    padding: 0 40px;
    h4 {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 28px;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 34px;
    }
  }
  .explains {
    padding: 0 40px;
    margin-top: 62px;

    h4 {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-bottom: 28px;
    }
    .security_code {
      margin-top: 58px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .btn {
        margin-top: 90px;
        width: 382px;
        height: 96px;
        background: #b1b1b1;
        border-radius: 48px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .btns {
        margin-top: 90px;
        width: 382px;
        height: 96px;
        background: #27a5de;
        border-radius: 48px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      input {
        width: 588px;
        height: 84px;
        background: #f1f1f1;
        border-radius: 8px;
        padding-left: 20px;
      }
      .verify_code {
        margin-top: 26px;
        width: 588px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        input {
          width: 424px;
          height: 84px;
          background: #f1f1f1;
          border-radius: 8px;
          padding-left: 20px;
        }
        img {
          width: 146px;
          height: 84px;
          background: #fafafa;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
